import { useState } from "react";
import { ethers } from 'ethers';
import { Box , Button , Flex , Input , Text } from '@chakra-ui/react';
import Astronauts from './Astronauts.json';

const AstronautsAddress = '0x4bed81deee24b7431331305c3ad4f81d00af07e3';

const MainMint = ({accounts, setAccounts }) => {
  const [ mintAmount, setMintAmount ] = useState(1);
  const isConnected = Boolean(accounts[0]);

  async function handleMint(){
    if(window.ethereum){
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const contract = new ethers.Contract(
        AstronautsAddress,
        Astronauts.abi,
        signer
      );
      try{
        const response = await contract.mint(mintAmount, {
          value: ethers.parseUnits((0.02 * mintAmount).toString()),
        });
        console.log('Response: ', response);
      }catch(err){
        console.log("Error: ", err);
      }
    }
  }

  const handleDecrement = () => {
    if(mintAmount <= 1) return;
    setMintAmount(mintAmount - 1);
  };

  const handleIncrement = () => {
    if(mintAmount >= 3) return;
    setMintAmount(mintAmount + 1);
  };

  return (
    <Flex
      justify="center"
      align="center"
      height="100vh"
      paddingBottom="150px"
    >
      <Box width="520px">
          <div>
            <Text fontSize="48px" textShadow="0 5px #000000">Astronauts</Text>
              <Text
                fontSize="30px"
                letterSpacing="-5.5%"
                fontFamily="VT323"
                textShadow="0 2px 2px #000000"
              >
                It's 2078. Can the Astronauts save humans from destructive rampant
                NFT speculation ? Mint Astronauts to find out
              </Text>
          </div>


          {isConnected ? (
            <div>
              <Flex
                justify="center"
                align="center"
              >
                  <Button 
                      backgroundColor="#D6517D"
                      borderRadius="5px"
                      boxShadow="0px 2px 2px 1px #0F0F0F"
                      color="white"
                      cursor="pointer"
                      fontFamily="inherit"
                      padding="15px"
                      marginTop="10px"
                      onClick={handleDecrement}
                  >
                    -
                  </Button>

                  <Input 
                    readOnly
                    fontFamily="inherit"
                    width="100px"
                    height="40px"
                    textAlign="center"
                    paddingLeft="19px"
                    marginTop="10px"
                    type="number"
                    value={mintAmount}
                    onChange={(e) => setMintAmount(e.target.value)} 
                  />
                  
                  <Button 
                      backgroundColor="#D6517D"
                      borderRadius="5px"
                      boxShadow="0px 2px 2px 1px #0F0F0F"
                      color="white"
                      cursor="pointer"
                      fontFamily="inherit"
                      padding="15px"
                      marginTop="10px"
                      onClick={handleIncrement}
                  >
                    +
                  </Button>
              </Flex>

              <Button 
                      backgroundColor="#D6517D"
                      borderRadius="5px"
                      boxShadow="0px 2px 2px 1px #0F0F0F"
                      color="white"
                      cursor="pointer"
                      fontFamily="inherit"
                      padding="15px"
                      marginTop="10px"
                      onClick={handleMint}
                  >
                    MINT NOW
                  </Button>
            </div>
          ) : (
            <Text
              marginTop="70px"
              fontSize="30px"
              letterSpacing="-5.5%"
              fontFamily="VT323"
              textShadow="0 3px #000000"
              color="#D6517D"
            >
              You must be connected to mint.
            </Text>
          )}
      </Box>
    </Flex>
  );
};

export default MainMint;